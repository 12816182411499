<template>
  <div class="visits">
    <a-skeleton
      class="visits__skeleton-info"
      active
      :paragraph="false"
      :loading="!notifications"
    >
      <a-alert class="visits__info" type="info" show-icon>
        <template v-slot:icon><InfoCircleOutlined /></template>
        <template v-slot:description>
          <div v-if="notifications.length > 0">
            <div v-if="notifications[0].type === 'MESSAGE'">
              <a-row>
                <a-col :md="18">Lekarz odpowiedział na Twoje pytanie.</a-col>
                <a-col :md="6">
                  <a-button
                    @click="goToChat(notifications[0].appointmentId)"
                    type="link"
                  >
                    Przejdź do rozmowy z lekarzem
                  </a-button>
                </a-col>
              </a-row>
            </div>
            <div v-if="notifications[0].type === 'DOCUMENT'">
              <a-row>
                <a-col :md="18">Dodano nowy dokument do Twojej wizyty.</a-col>
                <a-col :md="6">
                  <a-button
                    @click="goToTheVisit(notifications[0].appointmentId)"
                    type="link"
                  >
                    Przejdź do dokumentów
                  </a-button>
                </a-col>
              </a-row>
            </div>
          </div>
          <div v-else>
            Wizytę umówisz lub odwołasz na {{ companyData.webpage }} oraz u
            naszych Doradców pod numerem
            <a style="font-weight:bold" :href="'tel:' + companyData.phone">{{
              companyData.phone
            }}</a
            >.
          </div>
        </template>
      </a-alert>
    </a-skeleton>

    <h1>Moje wizyty</h1>

    <a-table
      :columns="table.columns"
      :row-key="(record) => record.id"
      :data-source="table.data"
      :loading="table.loading"
      :pagination="table.pagination"
      @change="handleTableChange"
      :locale="{ emptyText: 'Brak danych' }"
    >
      <template v-slot:action="{ record }">
        <div class="visits__table-actions">
          <a-button
            :disabled="record.processStatus !== 'FINISHED'"
            @click="goToChat(record.id)"
            type="link"
          >
            <template v-slot:icon><MessageOutlined /></template>Rozmowa z
            lekarzem
          </a-button>
          <a-button @click="addDocuments(record.id)" type="link">
            <template v-slot:icon><PlusCircleOutlined /></template>Dodaj
            dokument
          </a-button>
          <a-button @click="goToTheVisit(record.id)" type="link">
            <template v-slot:icon><FileSearchOutlined /></template>Zobacz
            dokumenty
          </a-button>
        </div>
      </template>
    </a-table>
  </div>
</template>

<script>
import {
  InfoCircleOutlined,
  MessageOutlined,
  PlusCircleOutlined,
  FileSearchOutlined,
} from "@ant-design/icons-vue";

import { parseDate } from "@/utils";

export default {
  name: "Visits",
  inject: ["mode"],
  components: {
    InfoCircleOutlined,
    MessageOutlined,
    PlusCircleOutlined,
    FileSearchOutlined,
  },
  mounted() {
    this.$http
      .get(`patient-portal/api/notification`)
      .then((res) => {
        this.notifications = res.data;
      })
      .catch(() => {
        this.$notification.open({
          message: "Wystąpił błąd podczas wczytywania notyfikacji",
          class: "error",
        });
      });
    this.getVisits(this.table.pagination, {
      columnKey: "appointmentAt",
      field: "appointmentAt",
      order: "descend",
    });
  },
  data() {
    return {
      notifications: null,
      table: {
        data: [],
        columns: [
          {
            title: "Data i godzina wizyty",
            dataIndex: "appointmentAt",
            customRender: ({ record }) => {
              return parseDate(record.appointmentAt);
            },
            sorter: true,
            defaultSortOrder: "descend",
          },
          {
            title: "Lekarz",
            dataIndex: "doctor",
            sorter: true,
            customRender: ({ record }) => {
              return `${record.doctor}`;
            },
          },
          {
            title: "Status wizyty",
            dataIndex: "processStatus",
            customRender: ({ text }) => {
              const processStatusMap = {
                SEARCH_FOR_DOCTOR: "Szukanie lekarza",
                PAID: "Umówiona",
                PENDING: "Oczekuje na potwierdzenie",
                CANCELLED_BY_PATIENT: "Anulowana przez pacjenta",
                CANCELLED_BY_DOCTOR: "Anulowana przez lekarza",
                FINISHED: "Zakończona",
                WAITING_FOR_PAYMENT: "Oczekiwanie na płatność",
                DOCTOR_NOT_FOUND: "Nie znaleziono lekarza",
                PROCESSING_PAYMENT: "Przetwarzanie płatności",
                COLLECTING_FORM_DATA: "Formularz - uzupełnianie",
              };
              return processStatusMap[text];
            },
            sorter: true,
          },
          {
            title: "Opcje",
            key: "action",
            slots: { customRender: "action" },
          },
        ],
        loading: true,
        pagination: {
          current: 1,
          pageSize: 10,
          total: 0,
        },
      },
    };
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      this.getVisits(pagination, sorter);
    },
    addDocuments(visitId) {
      this.$router.push({
        name: "Visit",
        params: { id: visitId, showAddDocuments: true },
      });
    },
    goToTheVisit(visitId) {
      this.$router.push({ name: "Visit", params: { id: visitId } });
    },
    goToChat(visitId) {
      this.$router.push({ name: "Chat", params: { id: visitId } });
    },
    getVisits(pagination, sorter) {
      this.table.loading = true;
      let link = `patient-portal/api/appointments?page=${pagination.current -
        1}&size=${pagination.pageSize}`;
      if (sorter) {
        link += `&sort=${sorter.field},${
          sorter.order === "ascend" ? "ASC" : "DESC"
        }`;
      }

      this.$http
        .get(link)
        .then((res) => {
          this.table.loading = false;
          this.table.data = res.data.content;
          this.table.pagination = {
            current: res.data.pageable.pageNumber + 1,
            total: res.data.totalElements,
            pageSize: res.data.size,
          };
        })
        .catch(() => {
          this.$notification.open({
            message: "Wystąpił błąd podczas wczytywania wizyt",
            class: "error",
          });
        });
    },
  },
  computed: {
    companyData() {
      switch (this.mode) {
        case "GENERAL": {
          return {
            webpage: "homedoctor.pl",
            phone: "+48 22 688 79 25",
          };
        }
        case "PZU": {
          return {
            webpage: "moje.pzu.pl",
            phone: "801 405 905",
          };
        }
      }
      return {
        webpage: "homedoctor.pl",
        phone: "+48 22 688 79 25",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.visits {
  padding: 24px 10px;
  max-width: $container-width;
  margin: 0 auto;

  .visits__skeleton-info {
    margin-bottom: 34px;
    ::v-deep(.ant-skeleton-content .ant-skeleton-title) {
      height: 74px;
    }
  }

  .visits__info {
    margin-bottom: 34px;
    .ant-btn {
      float: right;
      margin-top: -6px;
      @media (max-width: 768px) {
        margin-top: 12px;
      }
    }
  }
  .visits__table-actions {
    .ant-btn {
      display: block;
    }
  }
}
</style>
